<template>
<div class="dashboard-container">
	<v-app>
		<!-- Header -->
		<div v-if="archiveStateProp === false" class="header-container">
			<!-- Create Document Action -->
			<div class="flex-grow-0 p-0 mr-1">
				<CButton @click="showOutgoingDocumentChooseModal" color="danger" size="sm">
					<CIcon name="cil-plus"/> Tambah Baru
				</CButton>
			</div>
			<!-- Search Box -->
			<div class="flex-grow-1 mb-1">
				<document-search-box :type-prop="'Keluar'"/>
			</div>
		</div>
		<div>
			<advance-search
				ref="advanceSearch"
				:doc-type-prop="'Keluar'"
				:category-options-prop="document.categoryOptions"
				:archive-state-prop="archiveStateProp"
				@apply-advance-filter="applyAdvanceFilter"
			/>
		</div>
		<hr class="bottom-devider"/>
		<!-- Body -->
		<div class="body-container">
			<div class="p-2 position-relative mb-10" style="min-height:150px">
				<CRow>
					<CCol v-for="(item, index) in document.data" :key="index" col="4">
						<document-card
							v-bind:item-prop="item"
							v-on:show-disposition-form="showDispositionForm">
						</document-card>
					</CCol>
				</CRow>
			</div>
			<div class="content-center">
				<div class="p-5" v-if="renderData === false && pagination.ending === true">
					<p class="text-center">Anda mencapai batas akhir dokumen.</p>
				</div>
				<div class="p-5" v-else-if="renderData === false && pagination.noData === true">
					<p class="text-center">Tidak ada data ditemukan.</p>
				</div>
				<div class="p-5" v-else-if="renderData === false && pagination.ending === false">
					<a @click="loadMore" class="btn btn-danger btn-sm text-white text-center">Muat lebih banyak.</a>
				</div>
				<div v-else class="loader content-center p-2">
					<v-progress-circular class="loadingDoc" indeterminate color="red"></v-progress-circular>
				</div>
			</div>
		</div>

		<CModal :show.sync="popupOutGoingModal.isShow" class="modal-create-doc">
            <template #header>
                <h5 class="modal-title">Susun Surat Baru</h5>
                <CButtonClose @click="popupOutGoingModal.isShow = false" class="text-gray"/>
            </template>
            <div class="p-1">
                <CRow>
                    <CCol col="6">
                        <CCard class="clickable p-0" @click="showOutgoingDocumentReadyToSendModal">
                            <CCardBody class="content-center">
                                <font-awesome-icon icon="print" class="text-warning" size="2x"/>
                            </CCardBody>
                            <CCardFooter class="content-center">
                                <b>Memindai Surat Keluar</b>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                    <CCol col="6">
                        <CCard class="clickable p-0" @click="showOutgoingDocumentModal">
                            <CCardBody class="content-center">
                                <font-awesome-icon icon="pen" class="text-warning" size="2x"/>
                            </CCardBody>
                            <CCardFooter class="content-center">
                                <b>Membuat Surat Keluar</b>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                </CRow>
            </div>
            <template #footer>
                <div></div>
            </template>
        </CModal>

		<!-- Outgoing Document Popup Modal -->
		<outgoing-document-form
			:popup-modal-prop="document.modal"
			:category-options-prop="document.categoryOptions"
			:tag-options-prop="pDispositionForm.receiverOptions"
            :nota-dinas-prop="false"
			v-bind:ready-send-prop="false"
			@close-popup-modal="closeOutgoingDocumentModal">
		</outgoing-document-form>
		<!-- Outgoing Ready Document Popup Modal -->
		<outgoing-document-form
			:popup-modal-prop="modalOutgoingDocumentReadyToSend"
			:category-options-prop="document.categoryOptions"
			:tag-options-prop="pDispositionForm.receiverOptions"
            :nota-dinas-prop="false"			
			v-bind:ready-send-prop="true"
			@close-popup-modal="closeOutgoingDocumentModal">
		</outgoing-document-form>
		<!-- Disposition Popup Modal & Input Form -->
		<disposition-form
			:popup-modal-prop="pDispositionForm.popupModal"
			:doc-id-prop="pDispositionForm.input.docId"
			:memo-record-id-prop="pDispositionForm.input.memoRecordId"
			:user-options-prop="pDispositionForm.receiverOptions"
			@close-popup-modal="closeDispositionForm">
		</disposition-form>
	</v-app>
</div>
</template>

<script>
import { mapState } from 'vuex'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import DocumentCard from '@/components/document/DocumentCard.vue'
import DispositionForm from '@/components/document/DispositionFormNew.vue'
import OutgoingDocumentForm from '@/components/document/OutgoingDocument.vue'
import DocumentSearchBox from '@/components/document/SearchBox.vue'
import AdvanceSearch from '@/components/document/AdvanceSearch.vue'

export default {
	names: 'Out',
	props: {
		archiveStateProp: {
			type: Boolean,
			default: false
		}
	},
    components: {
		'advance-search': AdvanceSearch,
		'document-search-box': DocumentSearchBox,
        'document-card': DocumentCard,
        'disposition-form': DispositionForm,
        'outgoing-document-form': OutgoingDocumentForm
    },
	data () {
		const document = {
			data: [],
			filters: {
				type: 'Keluar',
				isArchive: null,
				isPublish: null,
				isDone: false,
				search: null
			},
			sorts: null,
			categoryOptions: [],
			modal: {
				isShow: false
			}
		}
		const pagination = {
			itemsPerPage: 9,
			activePage: 1,
			itemLoaded: 0,
			ending: false,
			noData: false
		}
		const pDispositionForm = {
			editedIndex: -1,
			receiverOptions: [],
			popupModal: {
				isShow: false,
				modalTitle: 'Disposisi Surat'
			},
			input: {
                docId: 0,
                memoRecordId: null
			},
			defaultValue: {
                docId: 0,
                memoRecordId: null
			}
		}
		return {
			renderData: true,
			document,
			pagination,
			pDispositionForm,
			popupOutGoingModal: {
                isShow: false
            },
			modalOutgoingDocumentReadyToSend: {
				isShow: false
			}
		}
	},
	computed: {
		...mapState('auth', {
			loggedUserId: 'user_id',
			loggedUserPosition: 'position'
		}),
	},
	mounted () {
		this.getDocumentList()
			.then( data => {
				data.items.forEach((item) => {
					this.document.data.push(item)
				})
				this.renderData = false
				if(this.pagination.itemLoaded === 0) this.pagination.noData = true
				if(this.pagination.itemLoaded >= data.total) this.pagination.ending = true

				
				if(this.archiveStateProp) {
					var elementId = 'badgeOut'
					if(data.total == 0) {
						document.getElementById(elementId).classList.add('hide')
						document.getElementById(elementId).innerHTML=''
					} else {
						document.getElementById(elementId).classList.remove('hide')
						document.getElementById(elementId).innerHTML = (data.total > 99) ? '99+' : data.total
				
					}
				}
			})
		this.getCategoryOptions()
		this.getReceiverOptions()
	},
	methods: {
		// Load More
		loadMore () {
			this.pagination.activePage += 1
			this.getDocumentList()
			.then( data => {
				data.items.forEach((item) => {
					this.document.data.push(item)
				})
				this.renderData = false

				if(this.pagination.itemLoaded >= data.total) this.pagination.ending = true
			})
		},
		// Search Box
		documentFind (query) {
			this.document.data = []
			this.renderData = true
			if(query.length >= 3){
				this.document.filters.search = query
				this.getDocumentList()
				.then( data => {
					console.log(data.length)
					data.items.forEach((item) => {
						this.document.data.push(item)
					})
					this.renderData = false
					if(this.pagination.itemLoaded === 0) this.pagination.noData = true
					if(this.pagination.itemLoaded >= data.total) this.pagination.ending = true
				})
			}else if(query.length == 0){
				this.document.filters.search = null
				this.getDocumentList()
				.then( data => {
					console.log(data.length)
					data.items.forEach((item) => {
						this.document.data.push(item)
					})
					this.renderData = false
					if(this.pagination.itemLoaded === 0) this.pagination.noData = true
					if(this.pagination.itemLoaded >= data.total) this.pagination.ending = true
				})
			}
		},
		// Filter
		applyAdvanceFilter (params) {
			this.document.data = []
			let advanceSearchComp = this.$refs.advanceSearch
			advanceSearchComp.loading = true
			if (this.archiveStateProp)
				params.filters.isDone = true
			this.document.filters = params.filters
			this.document.sorts = params.sorts
			this.getDocumentList()
				.then( data => {
					data.items.forEach((item) => {
						this.document.data.push(item)
					})
					this.renderData = false
					advanceSearchComp.refreshFilterBadges()
				}).catch( err => {
					console.log('Failed fetch document data with filter')
					console.log(err)
				}).finally(() => {
					advanceSearchComp.loading = false
				})
		},
		getCategoryOptions () {
			this.$store.dispatch(
				{
					type: 'document/getCategory',
					pagination: {
						limit: 100,
						offset: 0
					},
					order: {
						orderType: 'asc'
					}
				}
			).then( res => {
				let items = []
				res.result.forEach((item) => {
					let rowData = {
						id: item.category_id,
						name: item.category_name,
						label: item.category_name
                    }
					items.push(rowData)
				})
				
                this.document.categoryOptions = items
			})
		},
		getDocumentList () {
			this.renderData = true
			if (this.archiveStateProp){
				this.document.filters.isDone = true
			}
			
			return new Promise((resolve, reject) => {
				this.$store.dispatch(
					{
						type: 'document/getDocument',
						pagination: {
							limit: this.pagination.itemsPerPage,
							offset: (this.pagination.activePage-1)*this.pagination.itemsPerPage
						},
						filter: this.document.filters,
						sort: this.document.sorts
					}
				).then( res => {
					let items = []

					let total = 0
					if (res) {
						total = (res.total_count)?res.total_count:res.result.length
						
						res.result.forEach((item) => {
							items.push(item)
						})
						this.pagination.itemLoaded += res.result.length
					}
					
					setTimeout(() => {
						resolve({
							items,
							total
						})
					}, 1000)
				})
			})
		},
		// Disposition
        getReceiverOptions () {
			this.$store.dispatch(
				{
					type: 'user/getUser',
					pagination: {
						limit: 100000,
						offset: 0
					},
					order: {
                        orderBy: 'fullname',
                        orderType: 'asc'
                    },
                    filter: {
                        isActive: 'true'
                    }
				}
			).then( res => {
				let items = []
				for (let i = 0; i < res.result.length; i++) {
					const item = res.result[i];
					let position = (item.position != null) ? item.position : ''
					let positionExisted = items.some(el => el.position === position)
					if ((item.position != this.loggedUserPosition) && !positionExisted) {
						let label = position.trim()
						let rowData = {
							label: label,
							id: item.user_id,
							userId: item.user_id,
							fullname: item.fullname,
							email: item.email,
							rank: item.rank,
							position: item.position,
							command: null
						}
						items.push(rowData)
					}
				}
				
				this.pDispositionForm.receiverOptions = items
			})
        },
		// Navigation and Functions

		showOutgoingDocumentChooseModal () {
			this.popupOutGoingModal.isShow = true
		},
		showOutgoingDocumentReadyToSendModal () {
			this.popupOutGoingModal.isShow = false
			this.modalOutgoingDocumentReadyToSend.isShow = true
		},
		showOutgoingDocumentModal () {
			this.popupOutGoingModal.isShow = false
			this.document.modal.isShow = true
		},
		closeOutgoingDocumentModal (refreshState) {
			this.modalOutgoingDocumentReadyToSend.isShow = false
			this.document.modal.isShow = false
			if (refreshState === true) {
				this.renderData = true
				this.getDocumentList()
					.then( data => {
						this.document.data = data.items
						this.pagination.pages = Math.ceil(data.total/this.pagination.itemsPerPage)
						this.renderData = false
					})
			}
		},
		showDispositionForm (item) {
            if (item != null) {
				let memoRecordId = (item.disposition && item.disposition.memo_record_id) ? item.disposition.memo_record_id : null
				this.pDispositionForm.input.docId = item.doc_id
				this.pDispositionForm.input.memoRecordId = memoRecordId
				this.pDispositionForm.popupModal.isShow = true
            }
		},
		closeDispositionForm (refreshState) {
			this.pDispositionForm.popupModal.isShow = false
			this.pDispositionForm.input = Object.assign({}, this.pDispositionForm.defaultValue)
		}
	}
}
</script>

<style scoped lang="scss">
#app {
	background-color: #f5f5f5;
}
.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
</style>